import { useState, useEffect } from 'react';
import {
    AppBar, Toolbar, Typography, Box, IconButton, Button,
    Card, CardHeader, CardContent, List, ListItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import axios from './utils/axios';


function App() {

    const [inProgress, setInProgress] = useState(false)

    const showPopup = data => {
          let message = ''
          let title = ''
          if(data?.status === 'success'){
              message = `
Подія: "${data.ticket?.tournament?.name}", ${data.ticket?.tournament?.date}
Тип: ${data.ticket?.payment?.name}
Ціна: ${data.ticket?.payment?.price}
Код: ${data.ticket?.payment?.telegram_from_id} / ${Date.parse(data.ticket?.payment?.created_at)/1000} / ${data.ticket?.number}
`;
              title = 'Успішно'
          }else{
              message = data.message
              title = 'Помилка'
          }

          window.Telegram.WebApp.showPopup({
              title: title,
              message: message,
              buttons: [
                  {
                      id: 'ok',
                      type: 'default',
                      text: 'Зрозуміло'
                  }
              ]
          }, (button) => {

          })
    }

    const openQRScanner = () => {
        window.Telegram.WebApp.showScanQrPopup({
            text: ''
        }, (value) => {
            if(!value || inProgress){
                return
            }
            const parts = value.split('|')
            if(parts[0].includes('GUEST_TICKET')){
                //return true
                setInProgress(true)
                axios.post(`https://api.arena.promo/api/ticket/${value}`, {
                    user: window.Telegram.WebApp.initDataUnsafe.user,
                    initData: window.Telegram.WebApp.initData,
                }).then(payload => {
                    setInProgress(false)
                    const data = payload.data
                    showPopup(data)
                }).catch(() => {
                    setInProgress(false)
                })
            }
        })
    }

    return (
         <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                  <Toolbar>
                      <IconButton
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          sx={{ mr: 2 }}
                      >
                          <MenuIcon />
                      </IconButton>

                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Квитки</Typography>

                      <Button
                          variant="contained"
                          color="warning"
                          startIcon={<QrCode2Icon />}
                          onClick={() => openQRScanner()}
                      >
                          Сканувати
                      </Button>
                  </Toolbar>
              </AppBar>

              <Card>
                  <CardHeader title="Інструкція з використання" />

                  <CardContent>
                      <List sx={{ listStyle: "decimal", pl: 4 }}>
                          <ListItem sx={{ display: "list-item" }}>
                              Натисніть кнопку "Сканувати"
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Наведіть камеру телефону на зображення QR коду квитка
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Якщо операція була успішна - ви побачите повідомлення "Успішно" з інформацієї про квиток
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Якщо квиток вже було скановано до цього моменту - ви побачите повідомлення з датою та часом сканування
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              При виникненні інших помилок - ви побачите їх деталі у повідомлені
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Історію про всі сканування квитків ви можете бачити у історії повідомлень даного боту
                          </ListItem>
                      </List>
                  </CardContent>
              </Card>

        </Box>
    );
}

export default App;
